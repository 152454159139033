

const stations = [
  {
    id: 'preproduction',
    label: 'Preproduction',
  },
  {
    id: 'cut',
    label: 'Cut',
  },
  {
    id: 'load',
    label: 'Load',
  }
]

export default stations;
