

export const blue = {
  500: '#3f51b5',
}


export const red = {
  50 : '#fcdbe7',
  100: '#f593b6',
  200: '#f26f9e',
  300: '#ef4b86',
  400: '#ec276e',
  500: '#d8135a',
  600: '#b4104b',
  700: '#900d3c',
  800: '#6c0a2d',
  900: '#48061e',
}

export const lime = {
  500: '#97db19'
}

export const yellow = {
  300: '#fadf49',
  400: '#f8d823',
  500: '#edca07'
}

export const orange = {
  500: '#ed5707',
}

const allColors = {
  blue,
  red,
  lime,
  yellow,
  orange,
}

export default allColors;
