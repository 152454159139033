import React, { useEffect } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import { getConfiguration, putConfiguration, resetConfiguration } from '../../services/manufacturing-api';


export default function Settings() {
  const auth = useAuth();
  const [changes, setChanges] = React.useState(false); // eslint-disable-line 
  const [editorContent, setEditorContent] = React.useState(JSON.stringify({}, null, 2));

  const loadConfig = async () => {
    if (auth.isAuthenticated && auth.user) {
      const token = `${auth.user?.id_token}`;
      const config = await getConfiguration(token);
      console.log('Loaded config.');
      // console.log(`config ${JSON.stringify(config, null, 2)}`);

      // Remove DB autogenerated fields
      delete config.id;
      delete config.createdAt;
      delete config.updatedAt;

      // Set the editor context      
      setEditorContent(JSON.stringify(config, null, 2));
    }
    else {
      console.log('Cannot load config. Not authenticated.');
    }
  };

  const saveConfig = async () => {
    if (auth.isAuthenticated && auth.user) {
      const token = `${auth.user?.id_token}`;
      const data = JSON.parse(editorContent);
      const config = await putConfiguration(data, token);
      console.log('Saved config.');
      console.log(`config ${JSON.stringify(config, null, 2)}`);

      // Refresh page
      window.location.reload();
    }
    else {
      console.log('Cannot save config. Not authenticated.');
    }
  };

  const resetConfig = async () => {
    if (auth.isAuthenticated && auth.user) {
      const token = `${auth.user?.id_token}`;
      await resetConfiguration(token);
      console.log('Reset config.');

      // Refresh page
      window.location.reload();
    }
    else {
      console.log('Cannot reset config. Not authenticated.');
    }
  };

  useEffect(() => {
    loadConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Container>
      <Typography variant="h4" sx={{mt: '1.5rem', mb: '1rem'}}>
        Settings
      </Typography>

      <Box sx={{mb: '1rem'}}>
        <Typography variant="body1">
          Edit the settings below. Click the save button to persist changes.
        </Typography>
      </Box>


      <Box sx={{mb: '.5rem'}}>
        <Button 
          variant="contained" disableElevation color="primary" size="small"
          onClick={saveConfig}>
          Save
        </Button>
        <Button sx={{ml: '.5rem'}} 
          variant="outlined" disableElevation color="error" size="small" 
          onClick={resetConfig}>
          Reset to defaults
        </Button>
      </Box>

      <Box sx={{ border: '1px solid #ccc' }}>
          <AceEditor
            placeholder="Edit settings here"
            mode="json"
            theme="tomorrow"
            name="admin-fix"
            // onLoad={this.onLoad}
            // onChange={this.onChange}
            width={'100%'}
            height={'calc(75vh - 50px)'}
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={editorContent}
            onChange={(value) => { setChanges(true); setEditorContent(value); }}
            setOptions={{
              // enableBasicAutocompletion: false,
              // enableLiveAutocompletion: false,
              // enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
              useWorker: false
            }}/>
          </Box>
    </Container>
  );
}